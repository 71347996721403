import React from 'react';

const Stop = () => {
    return (
      <React.Fragment>
        <div className="btn__base r5c3">
          <div className="btn"></div>
        </div>
      </React.Fragment>
    );
  };

export default Stop;