import React from 'react';

const OctaveUp = () => {
    return (
      <React.Fragment>
        <div className="btn__base r6c2">
          <div className="btn"></div>
        </div>
      </React.Fragment>
    );
  };

export default OctaveUp;