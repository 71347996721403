import React from 'react';

const OctaveDown = () => {
    return (
      <React.Fragment>
        <div className="btn__base r6c1">
          <div className="btn"></div>
        </div>
      </React.Fragment>
    );
  };

export default OctaveDown;